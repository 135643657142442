<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="75px"
    @submit="onSubmit"
  >
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: true }]"
      label="整改状态"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="fixStatus"
          :disabled="!isAdd"
          direction="horizontal"
        >
          <van-radio
            v-for="item in fixStatusTypes"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <template v-if="+fixStatus === 1">
      <van-field
        v-if="!isAdd"
        key="完成日期"
        :disabled="!isAdd"
        :required="isAdd"
        :rules="[{ required: true, message: '请选择点击选择时间' }]"
        :value="fixInfo.finishTime"
        :clickable="isAdd"
        label="完成日期"
        name="finishTime"
        :placeholder="placeholder('time')"
        readonly
        @click="onTimeClick"
      />
      <van-field
        key="原因分析"
        v-model="fixInfo.reasonAnalysis"
        autosize
        label="原因分析"
        :readonly="!isAdd"
        maxlength="500"
        name="reasonAnalysis"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('reasonAnalysis', 'fixInfo')"
      />
      <van-field
        key="整改情况"
        v-model="fixInfo.disposeDescription"
        :required="isAdd"
        :rules="[{ required: isAdd, message: '请填写延期原因' }]"
        autosize
        :readonly="!isAdd"
        label="整改情况"
        maxlength="500"
        name="disposeDescription"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('disposeDescription', 'fixInfo')"
      />
      <van-field
        :required="isAdd"
        :rules="[{ required: true }]"
        label="照片"
        name="have"
      >
        <template #input>
          <van-radio-group
            v-model="fixInfo.have"
            :disabled="!isAdd"
            direction="horizontal"
            @change="onRadioChange"
          >
            <van-radio :name="1">有</van-radio>
            <van-radio :name="0">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <template v-if="+fixInfo.have === 1">
        <van-field
          key="现场照片"
          class="details-page__last-from-item"
          label="现场照片"
          name="uploader"
          :required="isAdd"
          :rules="[{ required: isAdd, message: '请上传现场照片' }]"
        >
          <template #input>
            <image-uploader
              key="fixInfo-image-uploader"
              ref="image-uploader"
              v-model="fixInfo.fileList"
              :deletable="isAdd"
              :max-count="5"
              :disabled="!isAdd"
              @change="onImageChange"
            />
          </template>
        </van-field>
      </template>
      <template v-else>
        <van-field
          key="原因"
          v-model="fixInfo.noPicReason"
          :disabled="!isAdd"
          :required="isAdd"
          :rules="[{ required: true, message: '请填写原因' }]"
          :show-word-limit="isAdd"
          autosize
          label="原因"
          maxlength="500"
          :placeholder="placeholder()"
          rows="2"
          type="textarea"
          @input="handleInput('noPicReason', 'fixInfo')"
        />
      </template>
    </template>
    <template v-else>
      <van-field
        key="延期日期"
        :disabled="!isAdd"
        :required="isAdd"
        :rules="[{ required: true, message: '请选择点击选择时间' }]"
        :value="delayFixInfo.applyDate"
        clickable
        label="延期日期"
        name="applyDate"
        :placeholder="placeholder('time')"
        readonly
        @click="onTimeClick"
      />
      <van-field
        key="延期原因"
        v-model="delayFixInfo.reason"
        :disabled="!isAdd"
        :required="isAdd"
        :rules="[{ required: true, message: '请填写延期原因' }]"
        :show-word-limit="isAdd"
        autosize
        label="延期原因"
        maxlength="500"
        :placeholder="placeholder()"
        rows="2"
        type="textarea"
        @input="handleInput('reason', 'delayFixInfo')"
      />
    </template>
    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        :disabled="loading.status"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        :min-date="minDate"
        type="date"
        @cancel="showTimePicker = false"
        @confirm="onDatetimeConfirm"
      />
    </van-popup>
  </van-form>
</template>

<script>
import { fixStatusTypes } from "./utils/constant";
import { filterEmoji, parseTime, operateMessage } from "@/utils";
import { loading } from "@/utils/constant";
import { mapState } from "vuex";
import {
  finishDisposeTrouble,
  getTroubleFixFinishHistoryById,
  getTroubleInfoById,
  delayTrouble,
  getTroubleModifyInfoById
} from "@/api/improve/improve";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import { getFileUrlForFileSystem } from "@/utils/file";

export default {
  components: { ImageUploader },
  props: ["id"],
  data() {
    return {
      isAdd: false,
      fixStatusTypes,
      fixStatus: 1,
      showTimePicker: false,
      minDate: new Date(),
      initTime: new Date(),
      loading: { ...loading },
      delayFixInfo: {
        reason: "",
        applyDate: "",
        troubleId: ""
      },
      troubleInfo: {},
      fixInfo: {
        have: 1,
        noPicReason: "",
        reasonAnalysis: "",
        troubleId: "",
        finishTime: "",
        disposeDescription: "",
        files: [],
        fileList: [],
        finishedFiles: [],
        passStatus: 1
      }
    };
  },
  created() {
    this.isAdd = JSON.parse(this.$route.query.add);
  },
  watch: {
    fixStatus: {
      async handler(val) {
        await this.$nextTick();
        +val === 1 &&
          this.fixInfo.fileList.length &&
          this.$refs["image-uploader"].setInnerValue(this.fixInfo.fileList);
      }
    }
  },
  mounted() {
    if (!this.isAdd) {
      this.getInfo();
    } else {
      this.getHistory();
    }
  },
  methods: {
    async onRadioChange() {
      if (this.fixInfo.have === 1) {
        await this.$nextTick();
        this.$refs["image-uploader"].setInnerValue(this.fixInfo.fileList);
      }
    },
    async getHistory() {
      await this.$nextTick();
      const info = await getTroubleFixFinishHistoryById(this.id);
      if (info?.disposeDescription) {
        this.fixInfo.disposeDescription = info.disposeDescription;
        this.fixInfo.reasonAnalysis = info.reasonAnalysis;
        this.fixInfo.fileList = this.fixInfo.files =
          info.files?.map(x => {
            return {
              ...x,
              url: getFileUrlForFileSystem(x.id, false),
              fileInfo: x,
              isImage: true,
              name: x.name
            };
          }) || [];
        this.fixInfo.have = info.files?.length ? 1 : 0;
        this.$refs["image-uploader"].setInnerValue(this.fixInfo.fileList);
      } else {
        await this.getTroubleInfo();
        this.fixInfo.reasonAnalysis = this.troubleInfo.reasonAnalysis;
        this.fixInfo.have = 1;
      }
    },
    async getTroubleInfo() {
      this.troubleInfo = await getTroubleInfoById(this.id);
    },
    placeholder(type = "content") {
      let content = "请输入内容";
      if (type === "time") {
        content = "点击选择时间";
      }
      return this.isAdd ? content : "--";
    },
    async getInfo() {
      try {
        this.fixInfo = await getTroubleModifyInfoById(this.id);
        this.formatterGetInfo();
      } catch (e) {
        console.log(e);
      }
    },
    onImageChange(val) {
      this.fixInfo.fileList = val;
    },
    formatterSaveInfo() {
      if (this.fixInfo.have) {
        this.fixInfo.files =
          this.fixInfo.fileList &&
          this.fixInfo.fileList.map(item => item.fileInfo);
      } else {
        this.fixInfo.files = [];
      }
      delete this.fixInfo.fileList;
    },
    async formatterGetInfo() {
      const result = this.fixInfo;
      this.fixInfo.fileList =
        result.files &&
        result.files.map(file => {
          return {
            url: getFileUrlForFileSystem(file.id, false),
            name: file.name,
            fileInfo: file,
            isImage: true
          };
        });
      this.fixInfo.have = this.fixInfo.fileList?.length ? 1 : 0;
      console.log(this.fixInfo, "this.fixInfo");
      await this.$nextTick();
      this.fixInfo.have === 1 &&
        this.$refs["image-uploader"].setInnerValue(this.fixInfo.fileList);
    },
    async onFinish() {
      try {
        this.formatterSaveInfo();
        const r = await finishDisposeTrouble(this.fixInfo);
        operateMessage(r, "整改完成");
        if (r) this.$router.back();
      } catch (e) {
        console.log("refuseTrouble -> e", e);
      }
    },
    async onDelay() {
      try {
        const r = await delayTrouble(this.delayFixInfo);
        operateMessage(r, "整改延期");
        if (r) this.$router.back();
      } catch (e) {
        console.log("refuseTrouble -> e", e);
      }
    },
    onSubmit() {
      if (+this.fixStatus === 1) {
        this.fixInfo.troubleId = this.id;
        this.onFinish();
      } else {
        this.delayFixInfo.troubleId = this.id;
        this.onDelay();
      }
    },
    handleInput(key, objKey) {
      this[objKey][key] = filterEmoji(this[objKey][key]);
    },
    onTimeClick() {
      if (!this.isAdd) return;
      if (this.fixStatus === 1) {
        this.fixInfo.finishTime &&
          (this.initTime = new Date(this.fixInfo.finishTime));
      } else {
        this.delayFixInfo.applyDate &&
          (this.initTime = new Date(this.delayFixInfo.applyDate));
      }
      this.showTimePicker = true;
    },
    onDatetimeConfirm(time) {
      if (this.fixStatus === 1) {
        this.fixInfo.finishTime = parseTime(time, "{y}-{m}-{d}");
      } else {
        this.delayFixInfo.applyDate = parseTime(time, "{y}-{m}-{d}");
      }
      this.showTimePicker = false;
    }
  }
};
</script>

<style></style>
